@tailwind base;

@tailwind components;

@tailwind utilities;

/* purgecss start ignore */
/* Markdown Styles */
/* Global */
.active {
  background-color: #c67e6f;
}

.text-purple {
  color: #4a2359;
}
.clap_button > svg {
  display: initial;
}

.font-inter {
  font-family: "Inter", sans-serif;
}
.font-source-sans-pro {
  font-family: "Source Sans Pro", sans-serif;
}
.font-dm-sans {
  font-family: "DM Sans", sans-serif;
}
.font-muli {
  font-family: "Muli", sans-serif;
}

button:focus {
  outline: none;
}

.psuedo-color-bg-gray-100::before {
  box-shadow: 0 8px #f7fafc;
}
.psuedo-color-bg-gray-100::after {
  box-shadow: 0 -8px #f7fafc;
}
.psuedo-color-bg-white::before {
  box-shadow: 0 8px #fff;
}
.psuedo-color-bg-white::after {
  box-shadow: 0 -8px #fff;
}
.psuedo-color-bg-gray-200::before {
  box-shadow: 0 8px#edf2f7;
}
.psuedo-color-bg-gray-200::after {
  box-shadow: 0 -8px#edf2f7;
}
.sidebar-option-rounded::after {
  bottom: -16px;
  border-radius: 0 50% 0;
  border-width: 0 20px 0 0;
  border-color: transparent;
  content: "";
  position: absolute;
  right: 0px;
  width: 16px;
  height: 16px;
}

.sidebar-option-rounded::before {
  top: -16px;
  border-radius: 0 0 50%;
  border-width: 0px 20px 0 0;
  border-color: transparent;
  content: "";
  position: absolute;
  right: 0px;
  width: 16px;
  height: 16px;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
  }
}

.spinner-grow {
  display: inline-block;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

*::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  border: 1px solid #d5d5d5;
}

/* ::-webkit-scrollbar-button {
}

::-webkit-scrollbar-corner {
} */

*::-webkit-scrollbar-thumb {
  background-color: #d3d3d3;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

*::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* ::-webkit-scrollbar-track-piece {
} */
.text-overflow-dots {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ReactModal__Overlay {
  opacity: 0;
  z-index: 100;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  z-index: 100;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ProseMirror {
  min-height: 150px;
  /* max-height: inherit;
  padding: 10px;
  --border-opacity: 1;
  border-color: #cbd5e0;
  border-color: rgba(203, 213, 224, var(--border-opacity));
  border-radius: 0.375rem;
  border-width: 1px; */
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid;
  border-right: 0.25em solid transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

@keyframes spinner-border {
  to {
    transform: rotate(1turn);
  }
}

/* MUI */
.mui-textfield-outlined {
  --pure-material-safari-helper1: rgb(
    var(--pure-material-primary-rgb, 33, 150, 243)
  );
  position: relative;
  display: inline-block;
  padding-top: 6px;
  font-family: var(
    --pure-material-font,
    "Roboto",
    "Segoe UI",
    BlinkMacSystemFont,
    system-ui,
    -apple-system
  );
  font-size: 16px;
  line-height: 1.5;
  overflow: hidden;
}

/* Input, Textarea */
.mui-textfield-outlined > input,
.mui-textfield-outlined > textarea {
  box-sizing: border-box;
  margin: 0;
  border: solid 1px; /* Safari */
  border-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
  border-top-color: transparent;
  border-radius: 4px;
  padding: 15px 13px 15px;
  width: 100%;
  height: inherit;
  color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
  background-color: transparent;
  box-shadow: none; /* Firefox */
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  caret-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
  transition: border 0.2s, box-shadow 0.2s;
}

/* Span */
.mui-textfield-outlined > input + span,
.mui-textfield-outlined > textarea + span {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  border-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
  width: 100%;
  max-height: 100%;
  color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
  font-size: 75%;
  line-height: 15px;
  cursor: text;
  transition: color 0.2s, font-size 0.2s, line-height 0.2s;
}

/* Corners */
.mui-textfield-outlined > input + span::before,
.mui-textfield-outlined > input + span::after,
.mui-textfield-outlined > textarea + span::before,
.mui-textfield-outlined > textarea + span::after {
  content: "";
  display: block;
  box-sizing: border-box;
  margin-top: 6px;
  border-top: solid 1px;
  border-top-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
  min-width: 10px;
  height: 8px;
  pointer-events: none;
  box-shadow: inset 0 1px transparent;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.mui-textfield-outlined > input + span::before,
.mui-textfield-outlined > textarea + span::before {
  margin-right: 4px;
  border-left: solid 1px transparent;
  border-radius: 4px 0;
}

.mui-textfield-outlined > input + span::after,
.mui-textfield-outlined > textarea + span::after {
  flex-grow: 1;
  margin-left: 4px;
  border-right: solid 1px transparent;
  border-radius: 0 4px;
}

/* Hover */
.mui-textfield-outlined:hover > input,
.mui-textfield-outlined:hover > textarea {
  border-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
  border-top-color: transparent;
}

.mui-textfield-outlined:hover > input + span::before,
.mui-textfield-outlined:hover > textarea + span::before,
.mui-textfield-outlined:hover > input + span::after,
.mui-textfield-outlined:hover > textarea + span::after {
  border-top-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
}

.mui-textfield-outlined:hover > input:not(:focus):placeholder-shown,
.mui-textfield-outlined:hover > textarea:not(:focus):placeholder-shown {
  border-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
}

/* Placeholder-shown */
.mui-textfield-outlined > input:not(:focus):placeholder-shown,
.mui-textfield-outlined > textarea:not(:focus):placeholder-shown {
  border-top-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
}

.mui-textfield-outlined > input:not(:focus):placeholder-shown + span,
.mui-textfield-outlined > textarea:not(:focus):placeholder-shown + span {
  font-size: inherit;
  line-height: 68px;
}

.mui-textfield-outlined > input:not(:focus):placeholder-shown + span::before,
.mui-textfield-outlined > textarea:not(:focus):placeholder-shown + span::before,
.mui-textfield-outlined > input:not(:focus):placeholder-shown + span::after,
.mui-textfield-outlined > textarea:not(:focus):placeholder-shown + span::after {
  border-top-color: transparent;
}

/* Focus */
.mui-textfield-outlined > input:focus,
.mui-textfield-outlined > textarea:focus {
  border-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
  border-top-color: transparent;
  box-shadow: inset 1px 0 var(--pure-material-safari-helper1),
    inset -1px 0 var(--pure-material-safari-helper1),
    inset 0 -1px var(--pure-material-safari-helper1);
  outline: none;
}

.mui-textfield-outlined > input:focus + span,
.mui-textfield-outlined > textarea:focus + span {
  color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
}

.mui-textfield-outlined > input:focus + span::before,
.mui-textfield-outlined > input:focus + span::after,
.mui-textfield-outlined > textarea:focus + span::before,
.mui-textfield-outlined > textarea:focus + span::after {
  border-top-color: var(--pure-material-safari-helper1) !important;
  box-shadow: inset 0 1px var(--pure-material-safari-helper1);
}

/* Disabled */
.mui-textfield-outlined > input:disabled,
.mui-textfield-outlined > input:disabled + span,
.mui-textfield-outlined > textarea:disabled,
.mui-textfield-outlined > textarea:disabled + span {
  border-color: rgba(
    var(--pure-material-onsurface-rgb, 0, 0, 0),
    0.38
  ) !important;
  border-top-color: transparent !important;
  color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
  pointer-events: none;
}

.mui-textfield-outlined > input:disabled + span::before,
.mui-textfield-outlined > input:disabled + span::after,
.mui-textfield-outlined > textarea:disabled + span::before,
.mui-textfield-outlined > textarea:disabled + span::after {
  border-top-color: rgba(
    var(--pure-material-onsurface-rgb, 0, 0, 0),
    0.38
  ) !important;
}

.mui-textfield-outlined > input:disabled:placeholder-shown,
.mui-textfield-outlined > input:disabled:placeholder-shown + span,
.mui-textfield-outlined > textarea:disabled:placeholder-shown,
.mui-textfield-outlined > textarea:disabled:placeholder-shown + span {
  border-top-color: rgba(
    var(--pure-material-onsurface-rgb, 0, 0, 0),
    0.38
  ) !important;
}

.mui-textfield-outlined > input:disabled:placeholder-shown + span::before,
.mui-textfield-outlined > input:disabled:placeholder-shown + span::after,
.mui-textfield-outlined > textarea:disabled:placeholder-shown + span::before,
.mui-textfield-outlined > textarea:disabled:placeholder-shown + span::after {
  border-top-color: transparent !important;
}

/* Faster transition in Safari for less noticable fractional font-size issue */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .mui-textfield-outlined > input,
    .mui-textfield-outlined > input + span,
    .mui-textfield-outlined > textarea,
    .mui-textfield-outlined > textarea + span,
    .mui-textfield-outlined > input + span::before,
    .mui-textfield-outlined > input + span::after,
    .mui-textfield-outlined > textarea + span::before,
    .mui-textfield-outlined > textarea + span::after {
      transition-duration: 0.1s;
    }
  }
}
/* MUI END */

.h1 {
  @apply text-3xl font-extrabold leading-tight tracking-tighter;
}

.h2 {
  @apply text-2xl font-extrabold leading-tight tracking-tighter;
}

.h3 {
  @apply text-2xl font-bold leading-tight;
}

.h4 {
  @apply text-xl font-bold leading-snug tracking-tight;
}

@screen md {
  .h1 {
    @apply text-4xl;
  }

  .h2 {
    @apply text-3xl;
  }
}

.btn,
.btn-sm {
  @apply font-medium inline-flex items-center justify-center border border-transparent rounded leading-snug transition duration-150 ease-in-out;
}

.btn {
  @apply px-8 py-3 shadow-lg;
}

.btn-sm {
  @apply px-4 py-2 shadow;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox,
.form-radio {
  @apply bg-white border border-gray-300 focus:border-gray-500;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox {
  @apply rounded;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select {
  @apply py-3 px-4;
}

.form-input,
.form-textarea {
  @apply placeholder-gray-500;
}

.form-select {
  @apply pr-10;
}

.form-checkbox,
.form-radio {
  @apply text-gray-800 rounded-sm;
}

.lazy-load-image-background.opacity {
  opacity: 0;
}

.lazy-load-image-background.opacity.lazy-load-image-loaded {
  opacity: 1;
  transition: opacity 0.3s;
}
/* body {
  -webkit-transform: translate3d(0, 0, 0);
} */
[data-aos="zoom-y-out"] {
  transform: scaleX(1.03);
  opacity: 0;
  transition-property: transform, opacity;
}

/* .check[type="checkbox"],
.check[type="radio"] {
  @apply appearance-none border-primary-600 border transition-all duration-150 ease-in-out focus:ring focus:ring-primary-200 focus:ring-offset-0 focus:ring-opacity-50 shadow-sm text-primary-600;
}
.check[type="checkbox"]:checked,
.check[type="radio"]:checked {
  @apply bg-primary-600;
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
} */
/* 
.rounded-full[type="checkbox"]:checked,
.rounded-full[type="radio"]:checked {
  border-color: transparent;
  background-color: currentColor;
  background-size: 90% 90%;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
} */

.word-gap-wider {
  word-spacing: 0.05em;
}

.font-poppins {
  font-family: "Poppins", sans-serif;
}

.slick-list,
.slick-slider,
.slick-track {
  position: relative;
  display: block;
}
.slick-loading .slick-slide,
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: 0;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-list,
.slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  top: 0;
  left: 0;
}
.slick-track:after,
.slick-track:before {
  display: table;
  content: "";
}
.slick-track:after {
  clear: both;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
/* .slick-slide img {
  display: block;
} */
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
@charset 'UTF-8';
.slick-dots,
.slick-next,
.slick-prev {
  position: absolute;
  display: block;
  padding: 0;
}
.slick-dots li button:before,
.slick-next:before,
.slick-prev:before {
  font-family: slick;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* .slick-loading .slick-list {
  background: url(ajax-loader.gif) center center no-repeat #fff;
} */
/* @font-face {
  font-family: slick;
  font-weight: 400;
  font-style: normal;
  src: url(fonts/slick.eot);
  src: url(fonts/slick.eot?#iefix) format("embedded-opentype"),
    url(fonts/slick.woff) format("woff"),
    url(fonts/slick.ttf) format("truetype"),
    url(fonts/slick.svg#slick) format("svg");
} */
.slick-next,
.slick-prev {
  font-size: 0;
  line-height: 0;
  top: 50%;
  /* width: 20px;
  height: 20px; */
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  /* color: transparent; */
  z-index: 9;
  border: none;
  outline: 0;
  background: 0 0;
}
.slick-next:focus,
.slick-next:hover,
.slick-prev:focus,
.slick-prev:hover {
  outline: 0;
  background: 0 0;
}
.slick-next:focus:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-prev:hover:before {
  @apply opacity-100;
}
.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
  @apply opacity-25;
}
.slick-next:before,
.slick-prev:before {
  @apply leading-none opacity-75 text-white;
  font-size: 20px;
}
.slick-prev {
  left: -25px;
}
[dir="rtl"] .slick-prev {
  right: -25px;
  left: auto;
}
/* .slick-prev:before {
  content: "←";
}
.slick-next:before,
[dir="rtl"] .slick-prev:before {
  content: "→";
} */
.slick-next {
  right: -25px;
}
[dir="rtl"] .slick-next {
  right: auto;
  left: -25px;
}
/* [dir="rtl"] .slick-next:before {
  content: "←";
} */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}
.slick-dots {
  @apply w-full list-none m-0 text-center;
  bottom: -25px;
}
.slick-dots li {
  @apply relative inline-block cursor-pointer p-0;
  width: 20px;
  height: 20px;
  /* margin: 0 5px; */
  margin: 0 10px;
}
.slick-dots li button {
  @apply block text-transparent cursor-pointer border-0;
  font-size: 0;
  line-height: 0;
  width: 20px;
  height: 20px;
  padding: 5px;
  outline: 0;
  background: 0 0;
}
.slick-dots li button:focus,
.slick-dots li button:hover {
  outline: 0;
}
.slick-dots li button:focus:before,
.slick-dots li button:hover:before {
  @apply opacity-100;
}
.slick-dots li button:before {
  @apply absolute top-0 left-0 text-center text-black opacity-25;
  font-size: 6px;
  line-height: 20px;
  width: 20px;
  height: 20px;
  content: "•";
}
.slick-dots li.slick-active button:before {
  @apply opacity-75 text-black;
}
.blue-dots li.slick-active div span {
  @apply opacity-100;
}
.blue-dots li div span {
  @apply opacity-0;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* purgecss end ignore */
